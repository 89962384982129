import React from 'react';
import '../assets/styles/Global.scss';
import logotypeImage from '../assets/images/logotype.png';

const Header = () => {
    const scrollToSection = (id) => {
        document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="header">
            <div className="header__logotype">
                <img src={logotypeImage} alt="Логотип" />
            </div>
            <div className="header__buttons">
                <ul>
                    <li><button onClick={() => scrollToSection('vacanciesSection')}>Вакансии</button></li>
                    <li><button onClick={() => scrollToSection('statisticsSection')}>Статистика</button></li>
                    <li><button onClick={() => scrollToSection('contactsSection')}>Контакты</button></li>
                </ul>
            </div>
        </div>
    );
}

export default Header;