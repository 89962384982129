import React from 'react';
import '../assets/styles/Global.scss';
import findWorkIcon from '../assets/images/icons/find-work-icon.svg';
import telegramIcon from '../assets/images/icons/telegram-icon.svg';
import emailIcon from '../assets/images/icons/email-icon.svg';

const MainSection = () => {
    const scrollToSection = (id) => {
        document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="main-section">
            <h1 className="main-section__title">Найдите работу мечты <span className="title-accent">сегодня</span></h1>
            <h2 className="main-section__desc">WORKPRO — проект, который помогает соискателям найти работу в удобном для себя формате, а работодателям построить команду мечты, используя уникальные возможности </h2>
            <div className="main-section__buttons">
                <button onClick={() => scrollToSection('vacanciesSection')} className="buttons__find-work"><img src={findWorkIcon} alt="Найти работу"/>Найти работу</button>
                <div className="buttons_vertical-line"></div>
                <a target="_blank" href="https://t.me/VVORKPRO" className="buttons__telegram"><img src={telegramIcon} alt="Telegram"/></a>
                <a target="_blank" href="mailto:Titovasvw@yandex.ru" className="buttons__email"><img src={emailIcon} alt="Электронная почта"/></a>
            </div>
        </div>
    );
}

export default MainSection;