import React from 'react';
import '../assets/styles/Global.scss';

const Footer = () => {
    return (
        <div className="footer">
            <p>WorkPro © Все права защищены</p>
        </div>
    );
}

export default Footer;