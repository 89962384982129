import React from 'react';
import '../assets/styles/Global.scss';
import telegramIcon from '../assets/images/icons/telegram-icon.svg';
import emailIcon from '../assets/images/icons/email-icon.svg';

const ContactsSection = () => {
    return (
        <div id="contactsSection" className="contacts-section">
            <div className="contacts-section__container">
                <h3 className="contacts-title">Подпишитесь на нас, чтобы быть
                    в курсе всех событий</h3>
                <div className="contacts-buttons">
                    <ul>
                        <li><a target="_blank" href="https://t.me/VVORKPRO"><img src={telegramIcon}/> Telegram</a></li>
                        <li><a target="_blank" href="mailto:Titovasvw@yandex.ru"><img src={emailIcon}/> Электронная почта</a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default ContactsSection;