import React from 'react';
import '../assets/styles/Global.scss';
import chooseVacanciesIcon from '../assets/images/icons/choose-vacancies-icon.svg';
import contactEmployerIcon from '../assets/images/icons/contact-employer-icon.svg';
import getWorkIcon from '../assets/images/icons/get-work-icon.svg';


const StepsSection = () => {
    return (
        <div className="steps-section">
            <h3 className="steps-section__title">Получите работу за <span className="title-accent">3 простых шага</span></h3>
            <div className="steps-section__container">
                <div className="container-step">
                    <img src={chooseVacanciesIcon} alt="Найти работу"/>
                    <div className="step-title">Выберите вакансию</div>
                    <div className="step-desc">Найдите вакансии, которые соответствуют вашим навыкам и опыту</div>
                </div>
                <div className="container-step">
                    <img src={contactEmployerIcon} alt="Найти работу"/>
                    <div className="step-title">Свяжитесь с работодателем</div>
                    <div className="step-desc">Установите надежные связи с работодателями из ведущих компаний</div>
                </div>
                <div className="container-step">
                    <img src={getWorkIcon} alt="Найти работу"/>
                    <div className="step-title">Получите работу</div>
                    <div className="step-desc">Пройдите собеседования и получите работу своей мечты</div>
                </div>
            </div>
        </div>
    );
}

export default StepsSection;