import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/Global.scss';
import reportWebVitals from './reportWebVitals';
import Header from "./components/Header";
import MainSection from "./components/MainSection";
import StepsSection from "./components/StepsSection";
import VacanciesSection from "./components/VacanciesSection";
import StatisticsSection from "./components/StatisticsSection";
import ContactsSection from "./components/ContactsSection";
import Footer from "./components/Footer";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Header/>
        <MainSection/>
        <StepsSection/>
        <VacanciesSection/>
        <StatisticsSection/>
        <ContactsSection/>
        <Footer/>
    </React.StrictMode>
);

reportWebVitals();
