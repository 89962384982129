import React from 'react';
import '../assets/styles/Global.scss';

const StatisticsSection = () => {
    return (
        <div id="statisticsSection" className="statistics-section">
            <h3 className="statistics-section__title">Статистика</h3>
            <div className="statistics-section__container">
                <div className="statistics-item">
                    <p className="item-title">200+</p>
                    <p className="item-subtitle">Вакансий на сайте</p>
                    <p className="item-desc">На нашем сайте представлен широкий выбор вакансий из разных отраслей и сферы деятельности, что увеличивает ваши шансы найти подходящую работу</p>
                </div>
                <div className="statistics-item">
                    <p className="item-title">50+</p>
                    <p className="item-subtitle">Работодателей</p>
                    <p className="item-desc">Сотрудничаем с ведущими работодателями, что гарантирует наличие вакансий и возможность трудоустройства в престижные компании</p>
                </div>
                <div className="statistics-item">
                    <p className="item-title">90%</p>
                    <p className="item-subtitle">Успех в трудоустройстве</p>
                    <p className="item-desc">Наш сайт имеет высокий показатель трудоустройства, что свидетельствует об эффективности наших инструментов поиска работы</p>
                </div>
            </div>
        </div>
    );
}

export default StatisticsSection;